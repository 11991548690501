import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import './PageBanner.css';

const PageBanner = ({ title }) => {

    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativePath: {in: ["banner1.jpg", "banner2.jpg", "banner3.jpg", "banner4.jpg", "banner5.jpg", "banner6.jpg"]}}) {
                edges {
                    node {
                        childImageSharp {
                            fluid(maxWidth: 2560, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            }
        }
      `
    );


    const { edges: banners } = data.allFile

    let banner = banners[3].node.childImageSharp.fluid;

    if (title === "Music") {
        banner = banners[1].node.childImageSharp.fluid;    
    }

    if (title === "Tour") {
        banner = banners[0].node.childImageSharp.fluid;
    }

    if (title === "Videos") {
        banner = banners[2].node.childImageSharp.fluid;
    }

    if (title === "Merch") {
        banner = banners[4].node.childImageSharp.fluid;
    }

    if (title === "Latest News") {
        banner = banners[5].node.childImageSharp.fluid;
    }
    
    return (
        <section className="page-banner">
            <Img 
                fluid={ banner } 
                className="page-banner__image" 
                objectFit="contain" 
                objectPosition="50% 50%" 
            />
            <div className="relative z-10 container">
                <div className="page-banner__grid">
                    <h1 className="page-banner__title">{ title }</h1>
                </div>
            </div>
        </section>
    )
}

export default PageBanner
