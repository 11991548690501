import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../components/SiteMetadata'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content/Content'
import PageBanner from '../components/PageBanner/PageBanner'

import Img from 'gatsby-image'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <PageBanner title={title} />

      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p>{description}</p>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { title } = useSiteMetadata()
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{`${post.frontmatter.title} | ${title}`}</title>
      </Helmet>
      <PageBanner title={ post.frontmatter.title } />

      <div className="container pb-88">
        <section>
          <Img fluid= {post.frontmatter.featuredimage.childImageSharp.fluid } alt={ post.frontmatter.title } />
        </section>
        <section className=" mt-64">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-10 md:col-start-2">
              <HTMLContent className="content" content={ post.html } />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
